const NO_UPLOAD_MODE = false
const UPLOAD_PROGRESS = false
import { AxiosConfig, PROVIDER, ENV, reUploadPop } from "../router";
import { ifUnauthorized } from "./index";
import patientFormUploadJSON/*, {ValueExtractor}*/ from "../hardcoded_JSONs/patientUploadForm"

function makeSeriesDescStrFromArr(form, key) { 
    for(const f of form) {if(f.name == key) {return f.seriesDescVal[0]}}
    //if(frm.value && frm.value.length > 4) {frm.keys.push(frm.value)} return frm.keys.join('|') 
}
const eventEvery = 25; let eventNum = 0, eventLoadedBefore = 0, timeBefore = 0

const baseState = { subject: JSON.parse(patientFormUploadJSON) }

/* eslint-disable no-unused-vars */
async function uploadFile(formDataArr, state, commit, STORE) {
    let startDate, previousLoaded = 0, upConstant = 0;    
    const onUploadProgress = (event) => {
        // if(eventNum % eventEvery == 0) {let dtNow = Date.now(); console.log((((event.loaded - previousLoaded)/1024))/((dtNow - startDate)/1000), "KiBs/sec"); previousLoaded = event.loaded; startDate = dtNow}
        if(UPLOAD_PROGRESS){ eventNum++; if(eventNum % eventEvery == 0) {
            console.log(`SPEED: ${((event.loaded - eventLoadedBefore) / (Date.now() - timeBefore)).toFixed(3)} ::`,`${((event.loaded / event.total)*100).toFixed(2)}%`, event)
            timeBefore = Date.now(); eventLoadedBefore = event.loaded
        }}
        state.progressDemo = 90 * (upConstant + (event.loaded / event.total)/formDataArr.length);
        window.dispatchEvent(new Event('uploading'))
        //console.log(event.loaded, event.total, event, state.pageDetails.progressBarWidth)
        //console.log(state.pageDetails.progressBarWidth);
    };
    try {
        let userSpecifics = {}
        const upForm = state.form.patientDetails
        AxiosConfig.defaults.headers.common["Content-Type"] = 'multipart/form-data';
        let str = '';
        state.form.outputOptions.filter((opt) => opt.selected).forEach((opt) => { str = str + opt.title + ',' })
        // state.form.outputOptions.filter((opt) => {
        //     if(Object.prototype.hasOwnProperty.call(opt, 'children')) { userSpecifics[opt.userSpecificProfileName] = ValueExtractor(opt) }
        // })

        str = str.substring(0, str.length - 1)
        const frm = state.form.requiredList


        let seriesDescs = {
            fmriSeriesDesc: makeSeriesDescStrFromArr(frm, 'rs-fMRI'),
            dmriSeriesDesc: makeSeriesDescStrFromArr(frm, 'dMRI'),
            t1wSeriesDesc: makeSeriesDescStrFromArr(frm, 'T1w'),
            t2wSeriesDesc: makeSeriesDescStrFromArr(frm, 'T2w'),
            t1cSeriesDesc: makeSeriesDescStrFromArr(frm, 'T1c'),
            flairSeriesDesc: makeSeriesDescStrFromArr(frm, 'FLAIR'),
        }

        if (state.form.acquisitionInfo.machineType === null) {state.form.acquisitionInfo.machineType = 'N/A'}
        
        for(const sd in seriesDescs) {if(seriesDescs[sd] == '' || seriesDescs[sd] == undefined) { seriesDescs[sd] = 'abc' }}
        try { 
            if (!NO_UPLOAD_MODE) { await AxiosConfig.post('/insertSeriesDesc', {}, {params: {provider: PROVIDER, env: ENV, ...seriesDescs}}) }
        } catch(e) { console.error(e) }
        let param = {
            totalChunks: formDataArr.length,
            patientId: upForm.patientID.value,
            disorder: upForm.condition.value == 'Others' ? upForm.condition.otherValue.replaceAll(' ', '_') : upForm.condition.value? upForm.condition.value : 'unknown',
            // '_' used in dev // disorder: upForm.condition.value == 'Others' ? upForm.condition.otherValue.replaceAll(' ', '_') : upForm.condition.value? upForm.condition.value : 'unknown',
            gender: upForm.patientSex.value ? upForm.patientSex.value : '-',
            processesSelected: str,
            acquisitionInfo: state.form.acquisitionInfo,
            age: upForm.age.value ? upForm.age.value : '-',
            isAnonymized: state.form.isAnonymized,
            isSurgical: state.form.isSurgical,
            ...seriesDescs,
            /* HARDCODED DO REMOVE!! */
            dmri_workflow_profile: 'op',
            moreIDP: state.form.advancedOutputState,
            // ...userSpecifics,
            env: ENV,
            provider: PROVIDER,
        }

        // if (state.form.advancedOutputState) {
        //     if(str === '') {str += 'IDPs'}
        //     else {str += ',IDPs'}
        // }

        if(STORE.getters['auth/getUser'].isHospitalUser) {
            const user = STORE.getters['auth/getUser']
            param.hospitalID = user.hospitalID
            param.studyID = user.studyID[0]
        }

        if (NO_UPLOAD_MODE) {
            console.log('PARAMS: ', param)
            console.log('BODY: ', formDataArr)
            await new Promise(r => setTimeout(r, 30_000));
        } else {
            const fileProcessAPI = 'startFileProcess'//(STORE.getters['auth/getUser'].isHospitalUser)? '/startFileProcess': '/startFileProcess'
            for(let [formDataIndex, formData] of formDataArr.entries()) {
                await STORE.dispatch('auth/refreshLogin') 
                timeBefore = Date.now(); eventLoadedBefore = 0;
                console.log(`${formDataIndex + 1} chunk started`)
                param['currChunk'] = formDataIndex + 1; upConstant = formDataIndex/formDataArr.length;
                console.log('param', param);
                const response = await AxiosConfig.post(fileProcessAPI, formData, {
                        headers: { "Content-Type": "multipart/form-data" },
                        params: param,
                        onUploadProgress,
                    },
                );
            }
        }
        console.log(upForm)
        console.log(state.progressDemo)
        commit("uploadSuccess", state)
        return Promise.resolve();
    } catch (error) {
        console.error(error)
        ifUnauthorized(error)
        if (state.progressDemo >= 90) {
            console.log(state.progressDemo)
            commit("uploadSuccess", state)
            return Promise.resolve();
        } else if (error.status == 504 && state.progressDemo >= 89) {
            console.log(state.progressDemo)
            commit("uploadSuccess", state)
            return Promise.resolve();
        }
        else {
            console.log(state.progressDemo)
            const errorMsg = error.response?.data?.msg ? error.response.data.msg : error.response?.data ? error.response.data : error.response ? error.response : error
            console.error(errorMsg)
            commit("uploadFailure", errorMsg)
            return Promise.reject();
        }
    } finally {
        state.downloadStarted = false
        state = structuredClone(baseState)
    }
}

export const patientUpload = {
    namespaced: true,
    state: structuredClone(baseState),
    getters: {
        getEloquent_Connectomics: (state) => {return state.subject.form.Eloquent_Connectomics},
        getCognitive_Connectomics: (state) => {return state.subject.form.Cognitive_Connectomics},
        reUploadFormArray: (state) => {
            return state.subject.reUploadArray.filter(x => x.isUploadFormsData)
        },
        reUploadArray: (state) => {
            return state.subject.reUploadArray.filter(x => x.isImagingDataReUpload)
        },
        downloadStarted: (state) => {
            return state.subject.downloadStarted
        },
        uploadOutcome: (state) => {
            return state.subject.uploadOutcome
        },
        pageDetails: (state) => {
            return state.subject.pageDetails
        },
        getPatientForm: (state) => {
            return state.subject.form
        },
    },
    actions: {
        async sendConfirmOutputOptions({state}, {patientID, procArr}) {
            console.log(this.state['auth']['user']['RadiologistId'],)
            const FORM = state.subject.form
            const systemsList = [...FORM.Eloquent_Connectomics, ...FORM.Cognitive_Connectomics]
            const tractSelectedSet = new Set(), networkSelectedSet = new Set();

            systemsList.forEach((sys) => {
                if(sys.netmap.selected) {sys.netmaps.forEach(nm => {networkSelectedSet.add(nm)})}
                if(sys.tract.selected) {sys.tracts.forEach(tract => {tractSelectedSet.add(tract)})}
            })

            const tractSelected = Array.from(tractSelectedSet), networkSelected = Array.from(networkSelectedSet);

            const modality = []
            if(tractSelected.length > 0) {modality.push('tractography')}
            if(networkSelected.length > 0) {modality.push('functional_connectomes')}

            const data = {
                userID: this.state['auth']['user']['RadiologistId'],
                patientID,
                tractSelected,
                networkSelected,
                provider: PROVIDER,
                env: ENV,
                modality,
            }
            
            // const response = await new Promise(r => setTimeout(r, 60_000));
            const response = await AxiosConfig.post('/outputSelected', data)
        },
        async uploadPatientForm({state}, {ID, radiologistid, disorder, executionid, patientid, FORM_DATA, fileName}) {
            let reUpload = { patientId: patientid, ID, disorder, radiologistid, ongoing: true, progress: 0, isUploadFormsData: true }

            const onUploadProgress = (event) => {
                reUpload.progress = 90 * (event.loaded / event.total);
            };

            state.subject.reUploadArray.push(reUpload)
            baseState.subject.reUploadArray.push(reUpload)

            const USER = this.getters['auth/getUser']
            AxiosConfig.post('/uploadFiles', FORM_DATA, {
                // headers: {radiologistid, disorder, executionid, patientid}, 
                params: {env: ENV, provider: PROVIDER, ID, radiologistID: radiologistid, disorder, fileName, executionID: executionid, patientID: patientid, studyID: USER.studyID[0], hospitalID: USER.hospitalID}, onUploadProgress,},
            ).finally(() => {reUpload.ongoing = false})
        },
        advancedOutputStateToggle({state}) {
            state.subject.form.advancedOutputState = !state.subject.form.advancedOutputState
        },
        async reUploadCase({ commit, dispatch, state }, payload) {
            let reUpload = { patientId: payload.patientId, case: payload.case, ongoing: true, progress: 0, isImagingDataReUpload: true }
            
            const onUploadProgress = (event) => {
                reUpload.progress = 90 * (event.loaded / event.total);
            };

            state.subject.reUploadArray.push(reUpload)
            baseState.subject.reUploadArray.push(reUpload)
            // console.log("STATE: ", state.subject.reUploadArray)
            let formData = new FormData();

            formData.append('file', payload.file)

            let param = {
                patientId: payload.patientId,
                modality: payload.case,
                env: ENV,
                provider: PROVIDER
            }

            try {
                if (NO_UPLOAD_MODE) {
                    while(reUpload.progress != 100) {reUpload.progress+= 5;await new Promise(r => setTimeout(r, 500));}
                } else {
                    var response = await AxiosConfig.post('/startReuploadProcess', formData, {
                        headers: { "Content-Type": "multipart/form-data" },
                        params: param,
                    onUploadProgress
                    });
                    console.log(response)
                }
            } catch (err) {
                console.error(err)
                let msgBody = `Re-Upload of ${payload.case} failed. Please try again`
                dispatch('patientUpload/setPopUp', { msg: 'Re-Upload', mode: "failure", buttonName: "", msgBody })
            } finally {
                reUpload.ongoing = false
                commit("uploadSuccess", state)
                setTimeout(() => {const idx = baseState.subject.reUploadArray.indexOf(reUpload); baseState.subject.reUploadArray.splice(idx, 1)}, reUploadPop) //removes from array after reUploadPop time
            }
        },
        resetForm({ commit }) {
            commit("resetState")
        },
        async upload({ commit }, state) {

            uploadFile(state.formdata, state, commit, this)// ? commit("uploadSuccess") : commit("uploadFailure")
        },
        checkPatientID({ commit }, state) {
            return AxiosConfig.post('/checkPatientID', {}, {
                params: { patientId: state.form.patientDetails.patientID.value, provider: PROVIDER, env: ENV },
                headers: { 'Content-Type': 'text/html; charset=utf-8', }
            }).then(
                (response) => {
                    // console.log(response.data)
                    commit("patienIdAvailable", state)
                    return Promise.resolve(response);
                }, (error) => {
                    ifUnauthorized(error.response.status)
                    console.error(error.response)
                    state.form.patientDetails.patientID.requiredMessage = error.response.data.msg
                    commit("patientIdNotAvailable", state)
                    return Promise.reject(error);
                }
            )
        },
        setSeriesDescFound({state}, payload) {state.form.setSeriesDescFound = payload}
    },
    mutations: {
        saveSeriesDesc(state, payload) { state.subject.saveSeriesDesc = payload },
        doNothing(state, payload) { console.log("LOG: ", state, payload) },
        uploadSuccess(state/*, subject*/) {

            state.subject.uploadOutcome.uploadSuccess = true;

            setTimeout(() => {
                state = structuredClone(baseState)
                this.state.patientUpload = baseState
            }, 20)
        },
        uploadFailure(state, error) {
            state.subject.serverResponse = error;
            state.subject.uploadOutcome.uploadFailure = true;
        },

        resetState(state) {
            state.subject = JSON.parse(patientFormUploadJSON)
        },

        patienIdAvailable(state) {
            state.subject.form.patientDetails.patientID.idAlreadyTaken = false;
        },
        patientIdNotAvailable(state) {
            state.subject.form.patientDetails.patientID.idAlreadyTaken = true;
            state.subject.form.patientDetails.patientID.value = "";
            setTimeout(() => {
                state.subject.form.patientDetails.patientID.idAlreadyTaken = false;
                state = structuredClone(baseState)
            }, 2500)
        }
    }
};