<template>
    <section class="state-of-art2">
        <div class="heading">Using the best tools</div>
        <div class="soa-items">
            <div class="soa1-item" v-for="item in this.soa1" v-bind:key="item.heading">
                <div class="item-heading">{{ item.heading }}</div>
                <div class="item-para">{{ item.para }}</div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: "StateOfArt2",
    data() {
        return {
            soa1: [
                { heading: "Despiking ", para: "Outlier based signal suppression without removing timepoints" },
                { heading: "Segmentation", para: "ANTs Atropos CSF, WM, GM tissue segmentation based ML algorithm" },
                { heading: "Slice-time Correction", para: "Automated slice time correction within seconds compensating  time difference between  slice acquisitions" },
                { heading: "Extensive motion correction", para: "Motion correction using 24 DOF" },
                { heading: "ICA ", para: "Decomposing the functional signals into different independent conponents dynamically" },
                { heading: "Network Mapping", para: "Adaptive network extraction & dynamic threshoding" },
                { heading: "Noise correction", para: "A self-supervised learning approach to remove significant additive noise" },
                { heading: "Diffusion metrics", para: "Generation of FA, ADC(MD), RD maps" },
            ]
        }
    }

}
</script>

<style>
.state-of-art2 {
    display: flex;
    flex-direction: column;
    box-shadow: 0px 6px 12px 5px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    gap: 4%;
}

.state-of-art2 .heading {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 240%;
    line-height: 200%;
    color: #000000;
    text-align: left;
    padding: 5% 9% 0%;
}

.state-of-art2 .soa-items {
    display: grid;
    grid-template-rows: repeat(2, 1fr);
    grid-template-columns: repeat(4, 1fr);
    gap: 5%;
    margin: 6% auto;
    width: 82%;
    text-align: left;
}

.state-of-art2 .soa-items .item-heading {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 120%;
    line-height: 150%;
    color: #000000;
}

.state-of-art2 .soa-items .item-para {
    font-family: 'SF Pro Text';
    font-style: normal;
    font-weight: 400;
    font-size: 100%;
    line-height: 150%;
    color: #000000;

}
</style>