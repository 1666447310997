import { AxiosConfig, AxiosConfig2 } from "../router";
import { PROVIDER, ENV, ReportURL, ViewerURL, AxiosReportConfig, AxiosConfigOrthancServ } from '../router'
import axios from "axios";

const DOWNLOAD_PROGRESS = false
const eventEvery = 30; let eventNum = 0

function catchErr(err, subjectID, msg, msgBody, dispatch) {
    if (err.response.status == 500 || err.status == 500) {
        dispatch('setPopUp', {
            msg,
            msgBody,
            mode: "failure",
            buttonName: "",
        });
    } else {
        dispatch('setPopUp', {
            msg, msgBody,
            mode: "failure",
            buttonName: "",
        });
    }
}

export const report = {
    state: {
        reportRequested: false,
        downloadStatus: []
    },
    getters: {
        allDownloadRequests: (state) => {
            let downloads = []
            state.downloadStatus.forEach(element => {
                if(element.downloading) {downloads.push(element.patientID)}
            });
            // console.log(downloads)
            return downloads
        },
        allOngoingDownloadProgress: (state) => {
            return state.downloadStatus.filter(x => x.downloading)
        }
    },
    mutations: {
        setReportRequested: (state, data) => {
            state.reportRequested = data
        }
    },
    actions: {
        // eslint-disable-next-line
        async openViewerInNewTab({state}, pat) {
            async function fetchReportInfo(payload) {
                try {
                  console.log(payload)
                  const reportData = await AxiosReportConfig.get("/reportdata");
                  var subjData = reportData.data.filter((el) => {
                    return el.ReportID == payload.subjectID
                  })
                  return subjData[0]
                }
                catch (e) {
                  console.log(e)
                }
            }

            async function fetchT1wSeriesInsUID(studyInsUID) {
                const allSeriesData = await AxiosConfigOrthancServ.get(`/studies/${studyInsUID}/series`);
                // dicomInfo.setAllSeriesData(allSeriesData.data)
                console.log(allSeriesData.data)
                let t1wSeriesInsUID = ""
                allSeriesData.data.forEach((serData) => {
                  if (serData['0008103E'].Value[0] == "T1w") {
                    t1wSeriesInsUID = serData['0020000E'].Value[0]
                  }
                })
                return t1wSeriesInsUID
            }

            let userID = this.state.auth.user.RadiologistId
            this.openingViewer = true;
            let reportID = userID + '-' + pat.patientID
            let reportData = await fetchReportInfo({ subjectID: reportID })
            const studyInsUID = reportData.ViewData.StudyInstanceUID
            let t1wSeriesInsUID = await fetchT1wSeriesInsUID(studyInsUID)
            window.open(`${ViewerURL}?studyID=${studyInsUID}&seriesID=${t1wSeriesInsUID}&reportID=${reportID}&userID=${userID}`, '_blank');
            this.openingViewer = false;
        },
        // eslint-disable-next-line
        openReportInNewTab({state}, pat) {
            let userID = this.state.auth.user.RadiologistId
            if (this.state.auth.user.isHospitalUser) {
                userID = this.state.auth.user.studyID[0] + this.state.auth.user.hospitalID
            }
            window.open(`${ReportURL}${userID}-${pat.patientID}/${pat.userID ?? this.state.auth.user.RadiologistId}/v2`, '_blank');
        },
        // eslint-disable-next-line
        openReportV1InNewTab({state}, pat) {
            window.open(`${ReportURL}${this.state.auth.user.RadiologistId}-${pat.patientID}`, '_blank');
        },
        requestReport({ commit }, payload) {
            return new Promise((resolve, reject) => {
                AxiosConfig.post("/email_service", { "patientId": payload.patientID }).then((res) => {
                    // console.log(res.data)
                    // formatTOPatientListJSON(res.data)
                    commit('setReportRequested', true)
                    // console.log(res.data)
                    // console.log("REPLY FROM SERVER Report API:  ", res.data)
                    resolve({ data: res.data })
                }, (err) => {
                    reject(err)
                })
            })
        },
        downloadSelectedPatient({ state, dispatch }, pat) {
            console.log('PAT: ', pat)
            let downloadStat = { patientID: pat.patientID, downloadProgress: 0, downloading: true }
            state.downloadStatus.push(downloadStat)
            const onDownloadProgress = (event) => {
                if(DOWNLOAD_PROGRESS){ eventNum++; if(eventNum % eventEvery == 0) {console.log(event, downloadStat.downloadProgress); /*console.log(`${((event.loaded / event.total)*100).toFixed(2)}%`, `${(STORE.state.auth.user.when - Date.now())/1000}s`, event)*/}}
                downloadStat.downloadProgress = 99 * (event.loaded / event.total);
                window.dispatchEvent(new Event('downloading'))
            };
            const extraParams = {}
            if(this.getters['auth/getUser'].isHospitalUser) {
                extraParams['studyID'] = this.getters['auth/getUser'].studyID[0]
                extraParams['hospitalID'] = this.getters['auth/getUser'].hospitalID
                extraParams['downloadPart'] = 'all'
            }
            return AxiosConfig2({
                method: "post",
                url: '/getDownloadURLs',
                responseType: "blob",
                data: {
                    ...extraParams,
                    patientID: pat.patientID,
                    provider: PROVIDER,
                    env: ENV,
                },
                onDownloadProgress, 
            })
            .then((response) => {
                // console.log('RESP: ', response)
                var blob = new Blob([response.data], {
                    type: response.headers["content-type"],
                });
                // console.log('BLOB: ',blob)
                axios({ method: 'get', url: blob, })
                var link = document.createElement("a");
                link.href = window.URL.createObjectURL(blob);
                link.download = `results-${pat.patientID}.zip`,
                link.click();
                downloadStat.downloading = false
                return Promise.resolve()
            })
            .catch((err) => {
                console.log(err)
                catchErr(
                    err,
                    pat.patientID,
                    "Download",
                    "The download did not go through due to an internal error",
                    dispatch
                );
                console.log(err.response.data.msg);
                downloadStat.downloading = false
                return Promise.reject()
            });
        },
        downloadWithAxios({ state, dispatch }, payload) {
            let downloadStat = { patientID: payload.subjectID, downloadProgress: 0, downloading: true }
            state.downloadStatus.push(downloadStat)
            const onDownloadProgress = (event) => {
                if(DOWNLOAD_PROGRESS){ eventNum++; if(eventNum % eventEvery == 0) {console.log(event, downloadStat.downloadProgress); /*console.log(`${((event.loaded / event.total)*100).toFixed(2)}%`, `${(STORE.state.auth.user.when - Date.now())/1000}s`, event)*/}}
                downloadStat.downloadProgress = 99 * (event.loaded / event.total);
                window.dispatchEvent(new Event('downloading'))
            };
            const extraParams = {}
            if(this.getters['auth/getUser'].isHospitalUser) {
                extraParams['studyID'] = this.getters['auth/getUser'].studyID[0]
                extraParams['hospitalID'] = this.getters['auth/getUser'].hospitalID
                extraParams['downloadPart'] = payload.key
            }
            return AxiosConfig2({
                method: "post",
                url: payload.url,
                responseType: "blob",
                data: {
                    ...extraParams,
                    patientID: payload.subjectID,
                    provider: PROVIDER,
                    env: ENV,
                },
                onDownloadProgress, 
            })
                .then((response) => {
                    // console.log('RESP: ', response)
                    var blob = new Blob([response.data], {
                        type: response.headers["content-type"],
                    });
                    // console.log('BLOB: ',blob)
                    axios({ method: 'get', url: blob, })
                    var link = document.createElement("a");
                    link.href = window.URL.createObjectURL(blob);
                    link.download = payload.name;
                    link.click();
                    downloadStat.downloading = false
                    return Promise.resolve()
                })
                .catch((err) => {
                    console.log(err)
                    catchErr(
                        err,
                        payload.subjectID,
                        "Download",
                        "The download did not go through due to an internal error",
                        dispatch
                    );
                    console.log(err.response.data.msg);
                    downloadStat.downloading = false
                    return Promise.reject()
                });
        },
    },
}