export const ImgViewer = {
  namespaced: true,
  state: {
   ImgArray: []
  },
  mutations: {
   setImgArray(state, data) {
      state.ImgArray = data
   }
  },
  getters: {
   getImgArray: (state) => {     
      return state.ImgArray
   }
  }
};
