//import AxiosConfig from "AxiosConfig";
import {AxiosConfig} from "../router";

//const AxiosConfig = AxiosConfig;

let setRight = function (set, right) {
  set.isRight = right
  set.isWrong = !right
}
/*
let unsetRightWrong = function(set) {
  set.isRight = false;
  set.isWrong = false;
}
*/
export const resetForm = {
  namespaced: true,
  state: {
    resetSuccess: false,
    emailVerified: false,
  },
  actions: {
    checkEmail({ commit }, f) {
      let emailField = f.email;
      if (emailField.isRight) {
        emailField.inVerification = true;
        AxiosConfig.post("/otpSet", {
          email: emailField.value,
        }).then((response) => {
          //console.log(response);
          if (response.status == 200) {
            commit("emailVerified");
          } else {
            commit("emailFailure")
          }
          emailField.inVerification = false;
        }).catch((error) => {
          console.log(error);
          f.invalidEmailPopup = true;
          //this.$data.wrongEmailsArray.push(emailField.value);
          setRight(emailField, false);
          emailField.inVerification = false;
          commit("emailFailure")
        });
      }
    },
    updatePassword({ commit }, f) {
      //console.log(f)
      f.inPasswordUpdate = true;
      AxiosConfig.post("/updatePassword", {
        email: f.email.value,
        password: f.password2.value,
        otp: f.otp.value
      }).then((response) => {
        //console.log(response)
        if (response.status == 200) {
          commit("resetSuccess")
          f.showPopup = true
        } else {
          commit("resetFailure")
        }
        f.inPasswordUpdate = false;
      }).catch((error) => {
        console.log(error)
        //this.$data.wrongOTPsArray.push(f.otp.value)
        f.inPasswordUpdate = false;
        setRight(f.otp, false)
        commit("resetFailure")
      })
    },
    resetLeave({ commit }) {
      commit("resetLeave")
    }
  },
  mutations: {
    resetSuccess(state) {
      state.resetSuccess = true;
    },
    emailVerified(state) {
      state.emailVerified = true;
    },
    resetFailure(state) {
      state.resetSuccess = false;
    },
    emailFailure(state) {
      state.emailVerified = false;
    },
    resetLeave(state) {
      state.emailVerified = false;
      state.resetSuccess = false;
    }
  },
};