<template>
  <section class="stage-teaser">
    <div class="stage-content">
      <div class="stage-content-info">
        <div class="stage-heading1">VOXELBOX EXPLORE</div>
        <div class="stage-heading2">
          Super charge your research with connectomics effortlessly
        </div>
        <div class="stage-text">
          VoxelBox Explore automates rs-fMRI, dMRI & sMRI processing for you!
        </div>

        <router-link to="/Login" class="stage-button">
          <b-button>Try Now</b-button>
        </router-link>
      </div>
      <div class="stage-image1">
        <!-- <img src="../assets/stage-image.png" alt="" width="100%" height="100%"> -->
        <video
          src="../assets/VB - 3 min.mp4"
          width="100%"
          height="100%"
          controls
        ></video>
      </div>
    </div>
    <!-- <div class="early-access-section">
            <div class="access-text">
                <b-form-input v-model="email" required placeholder="Enter email ID"></b-form-input>
            </div>

            <div class="access-button">
                <b-button @click="emailSubmit">Early access</b-button>
            </div>
            <div class="access-button">
                <b-button @click="earlyAccess">Early access</b-button>
            </div>
        </div> -->
    <!-- <b-modal id="bv-modal-earlyaccess" :no-close-on-backdrop="true" :no-close-on-esc="true" scrollable hide-footer
            size="xl">
            <div id="unique_id2" data-kol-snippet="contestbox" class="kol-contest-box-frame default"
                data-settings='{"embedHeight":"full","embedHeightPixels":"500"}'></div>
        </b-modal> -->
  </section>
</template>

<script>
export default {
  name: "StageTeaser",
  data() {
    return {
      email: "",
    };
  },
  methods: {
    /* emailSubmit() {
            console.log("success" + this.email)
            if (this.email != "") {
                var emailData = {
                    email: this.email
                }
                this.$store.dispatch("earlyaccess/emailEnter", emailData).then((res) => {
                    console.log(res)
                })
            }
            else {
                alert("Please Enter Email")
            }

        },
        earlyAccess(){
            this.$bvModal.show("bv-modal-earlyaccess");
        } */
  },
};
</script>

<style>
.stage-teaser {
  background-color: #0f0f2a;
  height: 815px;
  position: relative;
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  gap: 70%;
}

.stage-content {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: 100%;
}

.stage-content-info {
  display: flex;
  flex-direction: column;
  gap: 28px;
  position: absolute;
  left: 8%;
  top: 25%;
  width: 30%;
}

.stage-heading1 {
  font-family: "SF Pro Text", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 160%;
  line-height: 100%;
  text-align: left;
  letter-spacing: 0.2em;
  color: rgba(93, 250, 173, 0.916667);
}

.stage-heading2 {
  width: 115%;
  font-family: "SF Pro Text", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 300%;
  line-height: 120%;
  text-align: left;
  color: #ffffff;
}

.stage-image1 {
  width: 45%;
  height: 50%;
  left: 48%;
  top: 24%;
  position: absolute;
}

.stage-button {
  position: absolute;
  width: 45%;
  /* height: 7%; */
  left: 0%;
  top: 110%;
}

.stage-button .btn {
  width: 100%;
  background: #3c9e7f;
  box-shadow: 0px 4px 5px rgb(0 0 0 / 54%);
  border-radius: 5px;
}

.stage-button .btn:hover {
  background: transparent;
  border: 1px solid #3c9e7f;
}

.stage-text {
  /* position: absolute; */
  /* width: 32%; */
  height: 17%;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 150%;
  line-height: 175%;
  text-align: left;
  color: #ffffff;
}

.early-access-section {
  display: flex;
  justify-content: center;
  gap: 15px;
}

.access-text {
  width: 50%;
  height: 50px;
}

.access-text input {
  width: 100%;
  height: 100%;
  padding: 0 10%;
  font-size: 125%;
}

.access-button {
  width: 15%;
  height: 50px;
  box-shadow: 0px 4px 5px rgb(0 0 0 / 54%);
}

.access-button .btn {
  height: 100%;
  width: 100%;
  background: #3c9e7f;
  border-radius: 0px 8px 8px 0px;
  color: white;
  font-size: 120%;
  font-weight: 800;
}

@media only screen and (min-width: 320px) and (max-width: 425px) {
  .stage-content-info {
    width: 80%;
    top: 16%;
  }

  .stage-heading1 {
    font-size: 180%;
  }

  .stage-text {
    font-size: 175%;
  }

  .stage-image1 {
    width: 100%;
    height: 40%;
    left: 0%;
    top: 48%;
  }

  .early-access-section {
    gap: 5px;
  }

  .access-button {
    width: 20%;
  }
}

@media only screen and (min-width: 425px) and (max-width: 768px) {
  .stage-content-info {
    width: 80%;
    top: 16%;
  }

  /* .stage-heading1 {
        font-size: 170%;
    }

    .stage-text {
        font-size: 160%;
    } */

  .stage-image1 {
    width: 100%;
    height: 40%;
    left: 0%;
    top: 60%;
  }

  .early-access-section {
    gap: 5px;
  }

  .access-button {
    width: 20%;
  }
}
</style>