import { /*baseUrl2,*/ AxiosConfig2 /*AxiosConfig*/ } from "../router";
// import axios from "axios";
import { ifUnauthorized } from './index'
// eslint-disable-next-line no-unused-vars
let printNum = 0, printAt = 10

function setTotalPages(state) {
  let pages = Math.floor(state.usersList.length / state.numOfCases)
  if (state.usersList.length % state.numOfCases != 0) { pages += 1 }
  state.totalPages = pages
}

export const users = {
  state: {
    offline: false,
    consecutiveRequestFails: 0,
    requestFailLimits: 5,
    totalPages: 0,
    numOfCases: 5,
    currentPageNum: 1,
    usersList: [],
  },
  getters: {
    // limits: (state) => {
    //   let upperLim = (((state.currentPageNum + 1) * state.numOfCases) >= state.usersList.length) ? state.usersList.length : (state.currentPageNum + 1) * state.numOfCases
    //   let lowerLim = state.currentPageNum * state.numOfCases + 1
    //   return { lowerLim, upperLim }
    // },
    totalUsersPages: (state) => {
      return state.totalPages
    },
    // currentPageNum: (state) => {
    //   return state.currentPageNum
    // },
    // patients: (state) => {
    //   return state.renderedPatients
    // },
    // totalCases: (state) => {
    //   return state.usersList.length
    // },
    // renderedPatients: (state) => {
    //   return state.renderedPatients
    // },
    // noUpload: (state) => {
    //   // console.log("noUpload: ", state.noUpload)
    //   return state.noUpload
    // },
    // usersList: (state) => {
    //   return state.usersList
    // }
  },
  mutations: {
    getPatientsRequestStatus(state, bool) {
      if (bool) {
        state.consecutiveRequestFails = 0
        state.offline = false
      } else {
        state.consecutiveRequestFails+=1
        // state.offline = true
      }
      // console.log("FAILS: ",state.consecutiveRequestFails)
    },
    setAllUsers: (state, data) => {
      console.log(data)
      state.usersList = data
      let pageNum = state.currentPageNum
      let upperLim = (((state.currentPageNum + 1) * state.numOfCases) >= state.usersList.length) ? (state.usersList.length) : ((state.currentPageNum + 1) * state.numOfCases)
      state.renderedPatients = state.usersList.slice(pageNum * state.numOfCases, upperLim)

      printNum++
      setTotalPages(state)

      if (data.length > 0) {
        state.noUpload = false
      }
      else {
        state.noUpload = true
      }
    },
    dispNonePatients: (state) => {
      state.renderedPatients = []
    },
    displayPageChange: (state, pageNum) => {
      state.currentPageNum = pageNum
      let upperLim = (((state.currentPageNum + 1) * state.numOfCases) >= state.usersList.length) ? (state.usersList.length) : ((state.currentPageNum + 1) * state.numOfCases)
      let lowerLim = pageNum * state.numOfCases
      state.renderedPatients = state.usersList.slice(lowerLim, upperLim)
      setTotalPages(state)
    }
  },
  actions: {
    dispNone({ commit }) {
      commit('setusersList')
    },

    displayPage: ({ commit }, pageNum) => {
      commit('displayPageChange', pageNum)
    },

    // eslint-disable-next-line no-unused-vars
    fetchAllUsers({ commit, dispatch, state }) {
      if(state.usersList.length != 0) {return new Promise((resolve) => {console.log(state); resolve({data: state.usersList})})}
      return new Promise((resolve, reject) => {
        AxiosConfig2.get('/getAllUsersAsAdmin').then((res) => {
          commit('setAllUsers', res.data)
          resolve({ data: res.data })
        }, (err) => {
          ifUnauthorized(err)
          reject(err)
        })
      })
    }
  },
}
