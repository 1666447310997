<template>
  <section class="home-explore">
    <Header></Header>
    <StageTeaser></StageTeaser>
    <InputOutput></InputOutput>
    <Features></Features>
    <AccuracyMatrix></AccuracyMatrix>
    <StateOfArt></StateOfArt>
    <StateOfArt2></StateOfArt2>
    <!-- <ReachOut></ReachOut> -->
    <Footer></Footer>
  </section>
</template>

<script>
import Header from "@/components/HeaderNew.vue";
import StageTeaser from "@/components/StageTeaser.vue";
import InputOutput from "@/components/InputOutputProcess.vue";
import Features from "@/components/Features.vue";
import StateOfArt from "@/components/StateOfArt.vue";
import StateOfArt2 from "@/components/StateOfArt2.vue";
/* import ReachOut from "@/components/ReachOut.vue"; */
import Footer from "@/components/Footer.vue";
import AccuracyMatrix from "@/components/AccuracyMatrix.vue";
export default {
name:"HomeExplore",
components:{Header,StageTeaser,InputOutput,Features,StateOfArt,StateOfArt2,Footer,AccuracyMatrix}
}
</script>

<style>
body{
    font-size: 15px;
}
.home-explore{
  display: grid;
}
@media only screen and (min-width: 320px) and (max-width: 425px) {
    body {
        font-size: 8px;
    }
    
}
@media only screen and (min-width: 425px) and (max-width: 768px) {
    body {
        font-size: 10px;
    }
    
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
    body {
        font-size: 13px;
    }
    
}
</style>