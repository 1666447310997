<template>
    <section class="accuracy-matrix">
        <div class="heading">
            <div class="title">Performance Metrics</div>
            <div class="para">We benchmarked the performance of VoxelBox with other fMRI and dMRI research tools on a few
                datasets</div>
        </div>
        <div class="matrixes">
            <div class="matrix-item flex-column">
                <div class="item-heading">rs-fMRI</div>
                <div class="item-content flex-row">
                    <div class="item-label flex-column">
                        <p v-for="label in this.fMRILabels" v-bind:key="label">{{ label }}</p>
                    </div>
                    <div class="item-value flex-column">
                        <p v-for="value in this.fMRIValues" v-bind:key="value">{{ value }}</p>
                    </div>
                </div>
            </div>
            <div class="matrix-item flex-column">
                <div class="item-heading">dMRI</div>
                <div class="item-content flex-row">
                    <div class="item-label flex-column">
                        <div v-for="label in this.dMRILabels" v-bind:key="label.label">
                            <h3>{{ label.label }}</h3>
                            <p v-for="sublabel in label.sublabel" v-bind:key="sublabel">{{ sublabel }}</p>
                        </div>


                    </div>
                    <div class="item-value flex-column">
                        <p v-for="value in this.dMRIValues" v-bind:key="value">{{ value }}</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: "AccuracyMatrix",
    data() {
        return {
            fMRILabels: [
                "Processing time", "Parameter settings", "SNR", "tSNR", "CNR", "DICE score", "MAE", "MSE"
            ],
            fMRIValues: [
                "<40 min, in a single click", "Automated and Standardized", "+8%", "+8%", "1.4x higher", "1.5x higher", "-8%", "-6%"
            ],
            dMRILabels: [
                { label: "Pre-processing", sublabel: ["SNR"] },
                { label: "Modelling", sublabel: ["Goodness to fit (DTI)", "Goodness to fit (CSD)"] },
                { label: "Tractography", sublabel: ["Fiber to bundle coherency", "Linear fascicle evaluation (LiFE) RMSE"] }
            ],
            dMRIValues: [
                "+7%", "+12%", "+10%", "Better coherence", "-8%"
            ]
        }
    }
}
</script>

<style>
.font-poppins-normal {
    font-family: 'Poppins';
    font-style: normal;
}

.flex-column {
    display: flex;
    flex-direction: column;
}

.flex-row {
    display: flex;
    flex-direction: row;
}

.accuracy-matrix {
    display: flex;
    flex-direction: column;
    background: #0F0F2A;
    /* height: 690px; */
    font-family: 'Poppins';
    font-style: normal;
}

.accuracy-matrix .heading {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-content: start;
    text-align: left;
    padding: 3% 8%;
}

.accuracy-matrix .heading .title {
    font-weight: 600;
    font-size: 250%;
    color: #FFFFFF;
}

.accuracy-matrix .heading .para {
    font-weight: 400;
    font-size: 116%;
    line-height: 180%;
    color: rgba(255, 255, 255, 0.8);
}

.matrixes {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin-bottom: 5%;
}

.matrix-item {
    align-items: start;
    gap: 14px;
    padding-left: 20%;
}

.matrix-item .item-heading {
    font-weight: 600;
    font-size: 180%;
    color: #FFFFFF;
}

.matrix-item .item-content .item-label,
.matrix-item .item-content .item-value {
    border-radius: 8px;
    font-weight: 400;
    color: #000000;
    padding: 4%;
}

.matrix-item .item-content .item-label {
    background: rgba(255, 255, 255, 0.95);
}

.matrix-item .item-content .item-value {
    background: #FFFFFF;
    border: 5px solid #5DFAAD;
    box-shadow: 4px 4px 18px 6px rgba(0, 0, 0, 0.25);
}

.matrix-item:nth-of-type(2) .item-label h3 {
    font-size: 100%;
    font-weight: 800;
    text-align: left;
}

.matrix-item:nth-of-type(2) .item-label p {
    font-size: 100%;
    text-align: left;
    padding-left: 18px;
}

.matrix-item:nth-of-type(2) .item-value p {
    margin: 16px 0;
}

@media only screen and (min-width: 320px) and (max-width: 425px) {
    .matrixes {
        grid-template-columns: 1fr;
        gap: 20px;
        margin: 10px 0;
    }

    .matrix-item {
        padding-left: 0;
        margin: 2% auto;
    }

    .matrix-item:nth-of-type(2) .item-value p {
        margin: 14px 0;
    }

}

@media only screen and (min-width: 425px) and (max-width: 768px) {
    .matrixes {
        grid-template-columns: 1fr;
        gap: 20px;
        margin: 10px 0;
    }

    .matrix-item {
        padding-left: 0;
        margin: 2% auto;
    }

    .matrix-item:nth-of-type(2) .item-value p {
        margin: 14px 0;
    }

}
</style>