import Vue from 'vue'
import Vuex from 'vuex'
//import AxiosConfig from "../router";
import {auth} from './authModule'
import router from '../router'
import {earlyaccess} from './earlyaccess'
import {patientUpload} from './patientUpload'
import {resetForm} from './resetForm'
import {patients} from './patientsList'
import {report} from './report'
import {popUpStore} from './popUps'
import {users} from './usersList'
import {ImgViewer} from './ImgViewer'

Vue.use(Vuex)

export function ifUnauthorized(error) {
  if (error.status == 401 || error.response?.status == 401 || error.logoutStatus) {
    if(error.logoutStatus) {console.log('LOGGED OUT DUE TO INACTIVITY')}
    localStorage.removeItem('user')
    console.log('Unauthorised')
    // auth.actions.stopAuthInactivityLogging()
    router.push('/login');
  }
}

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    auth,
    patientUpload,
    resetForm,
    earlyaccess,
    patients,
    report,
    popUpStore,
    users,
    ImgViewer,
  }
})
