function ValueExtractor(tractographyObj) {
   let atleastOneSelected = false
   let arr = []
   tractographyObj.children.forEach((x, i) => {
      if(x.selected) {arr.push(i)}
      if(!atleastOneSelected && x.selected){atleastOneSelected = true}
   })

   if(!atleastOneSelected) {return "na"}
   else {
      const vals = tractographyObj.valueMethod
      for (let valKey in vals) {
         if(arr.every(x => vals[valKey].includes(x))) {return valKey}
      }
   }
}
let subject = {
   progressDemo: 0,
   uploadOutcome: {
      uploadSuccess: false,
      uploadFailure: false,
   },
   pageDetails: {
      showFirstPage: true,
      showSecondPage: false,
      showThirdPage: false,
      showFourthPage: false,
      showFifthPage: false,
      showSixthPage: false,
      showSeventhPage: false,
      progressStart: false,
      progressBarWidth: 0,
   },

   form: {
      isAnonymized: false,
      isSurgical: false,
      reportTumor: false,
      reportDementia: false,
      patientDetails: {
         patientID: {
            name: "Patient ID*",
            width: '100',
            required: true,
            requiredMessage: "This ID is already taken",
            placeholder: "4-digit number",
            idAlreadyTaken: false,
            type: "number",
            //length: 4,
            extraMargin: true,
            value: "",
         },
         condition: {
            width: '47',
            name: "Condition*",
            required: true,
            extraMargin: false,
            type: "dropdown",
            value: "",
            otherValue: "",
            placeholder: "(required)",
         },
         age: {
            name: "Age*",
            width: '20',
            required: true,
            hasRedBorder: false,
            extraMargin: false,
            value: "",
            type: "number",
            placeholder: "(required)",
         },
         patientSex: {
            name: "Sex*",
            required: true,
            width: '20',
            extraMargin: false,
            type: "dropdown",
            value: "",
            otherValue: "",
            placeholder: "(required)",
         },
      },
      outputOptions: [
         { title: 'Network Maps', scansRequired: ['rs-fMRI', 'T1w'], selected: false, price: 20 },
         { title: 'Tractography', scansRequired: ['dMRI', 'T1w'], selected: false, childrenVisible: false, price: 20, 
            // userSpecificProfileName: "dmri_workflow_profile", valueMethod: {"op": [1], "od": [0], "dap": [0, 1]},
            // children: [ {title: 'Deterministic Tractography', selected: false, }, {title: 'Probabilistic Tractography', selected: false } ] 
         },
         { title: 'Glioma Segmentation', scansRequired: ['T1w', 'T1c', 'FLAIR'], selected: false, price: 20 },
         { title: 'IDPs', scansRequired: ['rs-fMRI', 'T1w'], selected: false, price: 20, hidden: true },
      ],
      advancedOutputState: false,
      uploadFolderForm: [
         {name: 'rs-fMRI', key_name: 'fmri', value: '', keys: [], unique: true},
         {name: 'dMRI', key_name: 'dmri', value: '', keys: [], unique: true},
         {name: 'T1w', key_name: 't1w', value: '', keys: [], unique: true},
         {name: 'T2w', key_name: 't2w', value: '', keys: [], unique: true},
         {name: 'T1c', key_name: 't1c', value: '', keys: [], unique: true},
         {name: 'FLAIR', key_name: 'flair', value: '', keys: [], unique: true},
      ],
      Eloquent_Connectomics: [
         {
            name: 'Language System', 
            netmap: {name:'Whole Language Network, Broca’s, Wernicke’s, VPMC', selected: false},
            tract: {name:'Arcuate Fasciculus, Frontal Aslant, Superior Longitudinal Fasciculus, Inferior Fronto-Occipital Fasciculus', selected: false},
            netmaps: ['language', 'brocas', 'wernickes', 'vpmc'],
            tracts: ['AF', 'FAT', 'SLF1', 'SLF2', 'SLF3', 'IFOF']
         },
         {
            name: 'Sensorimotor System', 
            netmap: {name:'Sensorimotor Network', selected: false}, 
            tract: {name:'Corticospinal, Corticobulbar, Corticofugal, Corpus Callosum', selected: false},
            netmaps: ['sensorimotor'],
            tracts: ['CST_HCP', 'CST_Xtract', 'CC', 'SLF3'] //COBT::Corticofugal
         },
         {
            name: 'Visual System', 
            netmap: {name:'Visual Network', selected: false}, 
            tract: {name:'Optic Radiation, Inferior Fronto-Occipital Fasciculus, Corpus Callosum, Inferior Longitudinal Fasciculus', selected: false},
            netmaps: ['visual', 'primary_visual'],
            tracts: ['OR', 'IFOF', 'CC', 'ILF']
         },
         {
            name: 'Auditory System', 
            netmap: {name:'Auditory Network', selected: false}, 
            tract: {name:'Corpus Callosum', selected: false},
            netmaps: ['auditory'],
            tracts: ['CC']
         },
       ],
       Cognitive_Connectomics: [
         {
            name: 'Central Executive System', 
            netmap: {name:'Central Executive network', selected: false}, 
            tract: {name:'Inferior Fronto-Occipital Fasciculus, Uncinate Fasciculus, Superior Longitudinal Fasciculus', selected: false},
            netmaps: ['cen'],
            tracts: ['IFOF', 'SLF1', 'SLF2', 'SLF3'] //UF
         },
         {
            name: 'Default Mode System', 
            netmap: {name:'Default Mode network', selected: false}, 
            tract: {name:'Inferior Fronto-Occipital Fasciculus, Uncinate Fasciculus, Corpus Callosum, Inferior Longitudinal Fasciculus, Superior Longitudinal Fasciculus', selected: false},
            netmaps: ['dmn'],
            tracts: ['IFOF', 'CC', 'ILF', 'SLF1', 'SLF2', 'SLF3'] //UF
         },
         {
            name: 'Dorsal Attention System', 
            netmap: {name:'Dorsal Attention network', selected: false}, 
            tract: {name:'Superior Longitudinal Fasciculus', selected: false},
            netmaps: ['dan'],
            tracts: ['SLF1', 'SLF2', 'SLF3']
         },
         {
            name: 'Ventral Attention System', 
            netmap: {name:'Ventral Attention network', selected: false}, 
            tract: {name:'Superior Longitudinal Fasciculus', selected: false},
            netmaps: ['van'],
            tracts: ['SLF1', 'SLF2', 'SLF3']
         },
         {
            name: 'Salience System', 
            netmap: {name:'Salience network', selected: false}, 
            tract: {name:'Inferior Fronto-Occipital Fasciculus', selected: false},
            netmaps: ['salience'],
            tracts: ['IFOF']
         },
       ],
       acquisitionInfo: {
         machineType: null,
       },
      seriesDescFound: [],
      seriesUIDs: {},
      requiredList: [],
      dirName: "",
      directorySize: 0
   },
   serverResponse: '',
   file: null,
   downloadStarted: false,
   showDownload: true,
   saveSeriesDesc: null,
   reUploadArray: []
   //subjects: [],
}

let patientFormUploadJSON = JSON.stringify(subject)

export {ValueExtractor}

export default patientFormUploadJSON