<template>
  <div class="footer">
    <b-row>
      <b-col>
        <div class="footer-logo"></div>
      </b-col>
    </b-row>
    <b-row class="footer-item-section">
      <b-col
        cols="2"
        v-for="footerContent in footerContents"
        v-bind:key="footerContent.index"
      >
        <div class="footer-item-heading">{{ footerContent.heading }}</div>
        <div class="footer-item-content">
          <div
            class="footer-item-text"
            v-for="footerContentItem in footerContent.contentItems"
            v-bind:key="footerContentItem.index"
          >
            <a target="_blank" class="link-text" :href="footerContentItem.contentLinkTarget">
              {{ footerContentItem.contentText }}</a
            >
          </div>
        </div>
      </b-col>
    </b-row>
    <b-row class="footer-bootom-section">
      <b-col>
        <div
          class="footer-bootom-links"
          v-for="bottomLink in this.footerBottomLinks"
          v-bind:key="bottomLink.index"
        >
          <a target="_blank" class="link-text" :href="bottomLink.linkTarget">{{
            bottomLink.linkText
          }}</a>
        </div>
      </b-col>
      <b-col>
        <div
          class="footer-bottom-icons"
          v-for="bottomIcon in this.footerBottomIcons"
          v-bind:key="bottomIcon.index"
        >
          <a target="_blank" href=""
            ><img
              :src="require(`../assets/${bottomIcon.iconImage}.png`)"
              alt=""
          /></a>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {
      footerContents: [
        {
          heading: "About",
          contentItems: [
            {
              contentText: "Our story",
              contentLinkTarget: "https://www.brainsightai.com/our-team",
            },
            {
              contentText: "In the news",
              contentLinkTarget: "https://www.brainsightai.com/copy-of-publications",
            },
            {
              contentText: "Contact us",
              contentLinkTarget: "https://www.brainsightai.com/contact-3",
            },
            
            {
              contentText: "Friends of BrainSightAI",
              contentLinkTarget: "",
            },
            {
              contentText: "Careers",
              contentLinkTarget: "",
            },
          ],
        },
        {
          heading: "Research",
          contentItems: [
            {
              contentText: "Publications",
              contentLinkTarget: "https://www.brainsightai.com/journal-articles",
            },
            {
              contentText: "White Papers",
              contentLinkTarget: "https://www.brainsightai.com/white-papers",
            },
            {
              contentText: "Conferences",
              contentLinkTarget: "https://www.brainsightai.com/conference-papers",
            },
          ],
        },
        {
          heading: "Dialogues",
          contentItems: [
            {
              contentText: "Our Blog",
              contentLinkTarget: "https://www.blog.brainsightai.com",
            },
            {
              contentText: "Newsletter",
              contentLinkTarget: "https://brainsightai.substack.com",
            },
            {
              contentText: "Kokoro",
              contentLinkTarget: "https://www.brainsightai.com/kokoro",
            },
          ],
        },
        {
          heading: "Voxelpod",
          contentItems: [
            {
              contentText: "Dementia",
              contentLinkTarget: "",
            },
            {
              contentText: "Brain Tumor",
              contentLinkTarget: "",
            },
            {
              contentText: "COVID",
              contentLinkTarget: "https://www.brainsightai.com/events",
            },
          ],
        },
      ],
      footerBottomLinks: [
        {
          linkText: "BrainSight Inc. &reg; 2021",
          linkTarget: "",
        },
        {
          linkText: "PRIVACY POLICY",
          linkTarget: "https://vuestorage1.blob.core.windows.net/someshbsai/privacy_policy.pdf?sv=2020-04-08&st=2022-03-16T11%3A45%3A03Z&se=2025-03-17T11%3A45%3A00Z&sr=b&sp=r&sig=3yvtC74%2FCYSDyFDj2ntu4uQc9pKLGeKBkX9J%2FNRYqrw%3D",
        },
        {
          linkText: "TERMS OF USE",
          linkTarget: "https://vuestorage1.blob.core.windows.net/someshbsai/terms_conditions.pdf?sv=2020-04-08&st=2022-03-16T11%3A43%3A53Z&se=2025-03-17T11%3A43%3A00Z&sr=b&sp=r&sig=ZRFHLEDBYosPkI5DOLjoS6XxHy3e8f75XzocHsPdXzk%3D",
        },
      ],
      footerBottomIcons: [
        { iconImage: "facebook", iconTarget: "" },
        { iconImage: "twitter", iconTarget: "" },
        { iconImage: "instagram", iconTarget: "" },
        { iconImage: "linkedin", iconTarget: "" },
        { iconImage: "youtube", iconTarget: "" },
      ],
    };
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap");


.footer {
  width: 100%;
  height: 482px;
  background-image: url("../assets/footer-background.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.footer .link-text {
  text-decoration: none;
  color: #ffffff;
}
.footer .link-text:hover {
  text-decoration: none;
  color: #ffffff;
}
.footer-logo {
  width: 14%;
  height: 45px;
  margin: 3% 0% 2% 6%;
  background-image: url("../assets/footer-logo.png");
  background-repeat: no-repeat;
  background-position: center;
}

.footer-item-section::after {
  display: block;
  content: "";
  border-bottom: 1px solid rgba(93, 250, 173, 0.2);
  transform: translateX(13%);
  width: 60%;
  margin-top: 2%;
}

.footer-item-section .col-2 {
  margin-left: 3%;
}

.footer-item-heading {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 110%;
  line-height: 32px;
  color: #ffffff;
  width: 100%;
  margin-left: 24%;
  margin-top: 10%;
  text-align: left;
}

.footer-item-content {
  width: 100%;
  margin-left: 24%;
  margin-top: 4%;
  text-align: left;
  border-left: 2px solid #4bd7a1;
}

.footer-item-text {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 80%;
  line-height: 20px;
  color: #ffffff;
  width: 100%;
  margin-top: 4%;
  margin-left: 5%;
}

.footer-bootom-section {
  margin: 1% 0 0 6%;
}

.footer-bootom-links,
.footer-bottom-icons {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 70%;
  line-height: 20px;
  text-align: left;
  margin-right: 5%;
  float: left;
}

.footer-bootom-links a {
  text-decoration: none;
  color: rgba(93, 250, 173, 0.55);
}

.footer-bootom-links a:hover {
  text-decoration: none;
  color: rgba(93, 250, 173, 0.55);
}

@media (min-width: 768px) {
  .footer {
    height: 360px;
  }
  .footer-logo {
    width: 18%;
    background-size: 100%;
    margin: 4% 0% 2% 9%;
  }
  .footer-item-section {
    margin-left: 1%;
  }
  .footer-bootom-section {
    margin: 1% 0 0 7%;
  }
  .footer-item-section::after {
    transform: translateX(12%);
    width: 70%;
  }
  .footer-bottom-icons img {
    width: 82%;
  }
}
@media (min-width: 992px) {
  .footer {
    height: 450px;
  }
  .footer-item-section::after {
    transform: translateX(11%);
  }
}
@media (min-width: 1400px) {
  .footer {
    height: 482px;
  }
  .footer-logo {
    width: 14%;
    margin: 3% 0% 2% 6%;
    background-size: 100%;
  }
  .footer-item-section {
    margin-left: -1%;
  }
  .footer-bootom-section {
    margin: 1% 0 0 6%;
  }
  .footer-item-section::after {
    transform: translateX(11%);
    width: 70%;
  }
}

@media only screen and (min-width: 320px) and (max-width: 425px) {
  .footer-logo {
    width: 52%;
  }
}
</style>