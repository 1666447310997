import { AxiosConfig2 /*AxiosConfig*/ } from "../router";
// import patientsListJSON from "../hardcoded_JSONs/list_table_rows_v2";
import { ifUnauthorized } from './index'

// function printExact(a, x) { console.log(a, JSON.parse(JSON.stringify(x))) }

function setTotalPages(state) {
  let pages = Math.floor(state.allPatients.length / state.numOfCases)
  if (state.allPatients.length % state.numOfCases != 0) { pages += 1 }
  state.totalPages = pages
}


// eslint-disable-next-line no-unused-vars
let printNum = 0, printAt = 10

export const patients = {
  state: {
    adminSelectedRadID: null,
    offline: false,
    consecutiveRequestFails: 0,
    requestFailLimits: 5,
    allPatients: [],//patientsList
    renderedPatients: [], //patientsList,
    noUpload: false,
    totalPages: 0,
    numOfCases: 5,
    currentPageNum: 0,
    singlePatient: {}
  },
  getters: {
    limits: (state) => {
      let upperLim = (((state.currentPageNum + 1) * state.numOfCases) >= state.allPatients.length) ? state.allPatients.length : (state.currentPageNum + 1) * state.numOfCases
      let lowerLim = state.currentPageNum * state.numOfCases + 1
      // console.log("LIMITS: ", lowerLim, upperLim)
      return { lowerLim, upperLim }
    },
    totalPages: (state) => {
      return state.totalPages
    },
    currentPageNum: (state) => {
      return state.currentPageNum
    },
    patients: (state) => {
      return state.renderedPatients
    },
    totalCases: (state) => {
      return state.allPatients.length
    },
    renderedPatients: (state) => {
      return state.renderedPatients
    },
    noUpload: (state) => {
      return state.noUpload
    },
    allPatients: (state) => {
      return state.allPatients
    }
  },
  mutations: {
    getPatientsRequestStatus(state, bool) {
      if (bool) {
        state.consecutiveRequestFails = 0
        state.offline = false
      } else {
        state.consecutiveRequestFails+=1
        // state.offline = true
      }
    },
    setAdminSelectedRadID: (state, id) => {
      state.adminSelectedRadID = id
    },
    setAllPatients: (state, data) => {
      // console.log('Patients: ', data)
      state.allPatients = data
      let pageNum = state.currentPageNum
      let upperLim = (((state.currentPageNum + 1) * state.numOfCases) >= state.allPatients.length) ? (state.allPatients.length) : ((state.currentPageNum + 1) * state.numOfCases)
      state.renderedPatients = state.allPatients.slice(pageNum * state.numOfCases, upperLim)

      printNum++
      // if (printNum == printAt) { printNum = 0; console.log("REPLY FROM SERVER:  ", data) }
      setTotalPages(state)

      if (data.length > 0) {
        state.noUpload = false
      }
      else {
        state.noUpload = true
      }
    },
    dispNonePatients: (state) => {
      state.renderedPatients = []
    },
    displayPageChange: (state, pageNum) => {
      state.currentPageNum = pageNum
      let upperLim = (((state.currentPageNum + 1) * state.numOfCases) >= state.allPatients.length) ? (state.allPatients.length) : ((state.currentPageNum + 1) * state.numOfCases)
      let lowerLim = pageNum * state.numOfCases
      state.renderedPatients = state.allPatients.slice(lowerLim, upperLim)
      setTotalPages(state)
    }
  },
  actions: {
    dispNone({ commit }) {
      commit('setAllPatients')
    },

    displayPage: ({ commit }, pageNum) => {
      commit('displayPageChange', pageNum)
    },
    // eslint-disable-next-line no-unused-vars
    fetchAllHospitalPatients({commit}) {
      return new Promise((resolve, reject) => {
        let subjectsPromise;
        // console.log(this.state.auth.user)
        subjectsPromise = AxiosConfig2.get('/getSubjectsByHospital', {params: {hospitalID: this.state.auth.user.hospitalID, studyID: this.state.auth.user.studyID[0]}})
        subjectsPromise.then((res) => {
          commit('setAllPatients', res.data)
          commit('getPatientsRequestStatus', true)
          resolve({ data: res.data })
        }, (err) => {
          ifUnauthorized(err)
          commit('getPatientsRequestStatus', false)
          reject(err)
        })
      })
    },
    // eslint-disable-next-line no-unused-vars
    fetchAllPatients({ commit, dispatch, state }) {
      return new Promise((resolve, reject) => {
        let subjectsPromise;
        if (this.state.auth.user.admin && state.adminSelectedRadID) {subjectsPromise = AxiosConfig2.get('/getSubjectsByUserAsAdmin', {params: {radID: state.adminSelectedRadID}})}
        else {subjectsPromise = AxiosConfig2.get('/getSubjectsByUser')}
        // printExact('fetch token: ', AxiosConfig2.defaults.headers.common["x-access-token"])
        subjectsPromise.then((res) => {
          commit('setAllPatients', res.data)
          commit('getPatientsRequestStatus', true)
          resolve({ data: res.data })
        }, (err) => {
          ifUnauthorized(err)
          commit('getPatientsRequestStatus', false)
          reject(err)
        })
      })
    }
  },
}
