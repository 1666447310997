//import AxiosConfig from "AxiosConfig";
import router, {AxiosConfig} from "../router";

/* AxiosConfig.defaults.baseURL = 'http://dev-wbt-backend.brainsightai.com/'; */

export const earlyaccess = {
    namespaced: true,
    actions: {
        // eslint-disable-next-line no-unused-vars
        emailEnter({commit}, user) {
            console.log(user)
            return AxiosConfig
              .post("/email", {
                email: user.email,
              })
              .then(
                response => {
                  console.log(response.data)
                  // commit("")
                  router.push("/AccessThankyou");
                  return Promise.resolve(response.data);
                },
              ).catch(
                error => {
                  alert("Error in Email Service" + error.data)
                  return Promise.reject(error);
                }
              );
          },
    },
    mutations:{
    }
}