
export const popUpStore = {
   // namespaced: true,
   state: {
      popups: {
         popUpDisplayed: true,
         popUpInfo: {
            msg: '',
            msgBody: '',
            mode: 'success',
            buttonName: ''
         }
      }
   },
   getters: {
      popUpInfo: (state) => {
         return state.popups.popUpInfo
      },
      popUpDisplayed: (state) => {
         return state.popups.popUpDisplayed
      }
   },
   actions: {
      setPopUp({ commit }, newpopUpInfo) {
         // console.log("POPUP SET!")
         commit('setPopUp', newpopUpInfo)
      }
   },
   mutations: {
      setPopUp(state, newpopUpInfo) {
         state.popups.popUpDisplayed = false
         state.popups.popUpInfo = newpopUpInfo
      }
   }
};
