<template>
    <section class="state-of-art">
        <div class="heading">State of the art techniques</div>
        <div class="soa-items">
            <div class="soa1-item" v-for="item in this.soa1" v-bind:key="item.heading">
                <div class="item-heading">{{ item.heading }}</div>
            </div>
            <div class="soa2-item" v-for="item in this.soa2" v-bind:key="item.heading">
                <div class="item-heading">{{ item.heading }}</div>
                <div class="item-para">{{ item.para }}</div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: "StateOfArt",
    data() {
        return {
            soa1: [
                { heading: "AFNI based utility steps for image processing" },
                { heading: "Bias field correction & denoising of T1w which imporves CNR, SNR" },
                { heading: "Deep learning based methodology for skull-stripping of T1w & fMRI" },
                { heading: "ANTs based non-linear registration for coregistration & normalisation" },
            ],
            soa2: [
                { heading: "Multiple Artifact correction", para: "Raw MRI images undergo a series of artifact correction steps to remove Gibbs artifact, B0 susceptibility distortions, eddy current & motion induced artifacts." },
                { heading: "Probabilistic Tractogrphy", para: "More robust and sensitive in detecting white matter fibers" },
                { heading: "CSD modelling", para: "Unlike DTI, CSD accounts for crossing fibers by estimating  the distribution of fiber orientations in each voxel" },
                { heading: "Automated Fiber Quantification", para: "More robust and sensitive in detecting white matter fibers" },
            ]
        }
    }

}
</script>

<style>
.state-of-art {
    display: flex;
    flex-direction: column;
    background: #2A877B;
    box-shadow: 0px 6px 12px 5px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    gap: 4%;
}

.state-of-art .heading {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 240%;
    line-height: 200%;
    color: #FFFFFF;
    text-align: left;
    padding: 5% 9% 0%;
}

.soa-items {
    display: grid;
    grid-template-rows: repeat(2, 1fr);
    grid-template-columns: repeat(4, 1fr);
    gap: 5%;
    margin: 6% auto;
    width: 82%;
    text-align: left;
}

.soa-items .item-heading {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 120%;
    line-height: 150%;
    color: #FFFFFF;
}

.soa-items .item-para {
    font-family: 'SF Pro Text';
    font-style: normal;
    font-weight: 400;
    font-size: 100%;
    line-height: 150%;
    color: #FFFFFF;

}
</style>