<template>
    <section class="featues">
        <div class="heading">Features</div>
        <div class="feature-items">
            <div class="feature-item">
                <div class="item-heading">
                    <img src="../assets/Arrow 5.svg" alt="">
                    <p>Automates SOTA methods, saving time and giving robust results</p>
                </div>
                <div class="item-bullets">
                    <ul>
                        <li>Standardised processing steps based on <b>hundreds of publications and state of the art methods</b>
                            to minimise processing errors in your research </li>
                        <li>Robust brain parcellation into <b>functional networks</b> using atlas established by landmark
                            publications</li>
                        <li> <b>Automatic:</b>
                            <ul>
                                <li>Machine Learning enhanced post <b>processing of resting-state fMRI in 45 minutes</b></li>
                                <li><b>Individual based Independent Component Analysis</b></li>
                            </ul>
                        </li>

                    </ul>
                </div>
                <div class="item-coming-soon">
                    <b>Coming Soon</b>
                    <ul>
                        <li>Group based Independent Component Analysis</li>
                        <li>Machine learning powered post-processing of diffusion MRI; Tractography using CSD
                            and probabilistic methods </li>
                        <li>Eliminates the need of manual drawing of ROIs for Tractography</li>
                        <li>Image-derived phenotypes such as Fractional anisotropy (FA) and Apparent
                            diffusion coefficient (ADC) values</li>
                    </ul>
                </div>
            </div>
            <div class="feature-item">
                <div class="item-heading">
                    <img src="../assets/Arrow 5.svg" alt="">
                    <p>Enables advanced AI and computational analysis</p>
                </div>
                <div class="item-bullets">
                    <ul>
                        <li><b>Generates 9+ functional and cognitive networks</b></li>
                        <li>Access to <b>normative reference population </b>based on <b>the Human Connectome project</b></li>
                        <li><b>Gives outputs</b> in <b>6+ formats:</b>
                            <ul>
                                <li>Heat Maps</li>
                                <li>Brain Masks</li>
                                <li>Voxel z-score</li>
                                <li>Normalised Outputs</li>
                                <li>ALFF Maps</li>
                                <li>Confound file</li>
                            </ul>
                        </li>
                        <li>Provision for <b>corss-sectional & longitudinal analysis</b> of rs-fMRI, dMRI & sMRI</li>

                    </ul>
                </div>
                <div class="item-coming-soon">
                    <b>Coming Soon</b>
                    <ul>
                        <li>Access to 30+ tracts in .tck & NIfTI formats</li>
                    </ul>
                </div>
            </div>
            <div class="feature-item">
                <div class="item-heading">
                    <img src="../assets/Arrow 5.svg" alt="">
                    <p>Access to secured data ingestion</p>
                </div>
                <div class="item-bullets">
                    <ul>
                        <li><b>Access to FDA compliant data ingestion and integrated Connectomics</b> to <b>Fast tracks publication</b> and translate to industrial applications</li>
                        <li>Improves <b>publication success</b> rate and bridges the translation gap</li>

                    </ul>
                </div>
            </div>
            <div class="feature-item">
                <div class="item-heading">
                    <img src="../assets/Arrow 5.svg" alt="">
                    <p>Vendor neutral tool for superior results</p>
                </div>
                <div class="item-bullets">
                    <ul>
                        <li><b>Compatible</b> with <b>major MRI vendors</b></li>
                        <li>Accounts for <b>inter-scanner variability</b></li>
                        <li><b>Superior image quality checking</b> methods</li>
                    </ul>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: "Features"
}
</script>

<style>
.featues {
    display: flex;
    flex-direction: column;
}

.featues .heading {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 250%;
    line-height: 200%;
    color: #000000;
    text-align: left;
    padding: 2% 9%;
}

.feature-items {
    display: grid;
    /* grid-template-rows: repeat(2, 1fr); */
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr 0.5fr;
    gap: 5%;
    margin: 0 auto;
    width: 90%;

}

.feature-item {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: start;
    text-align: left;
}

.feature-item .item-heading{
    display: flex;
    font-weight: 600;
    font-size: 120%;
    gap: 5%;
    width: 100%;
}
.feature-item .item-heading, .feature-item .item-bullets, .feature-item .item-coming-soon {
    font-family: 'SF Pro Text';
    font-style: normal;
    line-height: 200%;
    color: #000000;
}

.feature-item .item-bullets {
    padding: 0 4%;
    font-size: 100%;
    color: rgba(0, 0, 0, 0.72);
}

.feature-item .item-coming-soon {
    padding: 0 8%;
    font-size: 100%;
    color: rgba(0, 0, 0, 0.72);
}

@media only screen and (min-width: 320px) and (max-width: 425px) {
    .feature-items {
    grid-template-columns: 1fr;
    gap: 0;
    }
    
}
@media only screen and (min-width: 425px) and (max-width: 768px) {
    .feature-items {
    grid-template-columns: 1fr;
    gap: 0;
    }
    
}
</style>