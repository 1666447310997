<template>
    <section class="input-output-section">
        <!-- <div class="io-item" v-for="item in this.io" v-bind:key="item.heading">
            <img src="../assets/Cloud upload.png" alt="">
            <div class="heading"> <b>{{ item.heading }}</b></div>
            <div class="para">
                <p>{{ item.para }}</p>
            </div>
        </div> -->
        <div class="io-item">
            <img src="../assets/Cloud upload.png" alt="">
            <div class="heading"> <b>Input</b></div>
            <div class="para">
                <p>Upload resting state - fMRI, dMRI & T1w</p>
            </div>
        </div>
        <div class="io-item">
            <img src="../assets/process.png" alt="">
            <div class="heading"> <b>Process</b></div>
            <div class="para">
                <p>Automatic rs-fMRI Processing<br>ICA Analysis<br>dMRI Processing<br>Tractography<br>Group-based
                    Analysis</p>
            </div>
        </div>
        <div class="io-item">
            <img src="../assets/output.png" alt="">
            <div class="heading"> <b>Output</b></div>
            <div class="para">
                <p>
                <ul>
                    <li>Network Maps & Region level stats</li>
                    <li>Individual & Group-based Independent components using ICA (coming soon)</li>
                    <li>Activity & Connectivity maps - ALFF, fALFF, z-fALFF, z-ALFF, ReHo, ReHO_sz, 4D-heat maps</li>
                    <li>Normalised output & comfound file</li>
                    <b>Coming soon:</b>
                    <ul>
                        <li>Group based independent components</li>
                        <li>White matter tracts</li>
                        <li>FA/ADC values</li>
                    </ul>
                </ul>
                </p>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: "InputOutput",
    data() {
        return {
            io: [
                {
                    heading: "Input",
                    para: "Upload resting state - fMRI, dMRI & T1w"
                },
                {
                    heading: "Process",
                    para: `Automatic rs-fMRI Processing\nICA Analysis\ndMRI Processing\nTractography\nGroup-based Analysis`
                },
                {
                    heading: "Output",
                    para: `- Network Maps & Region level stats\n - Individual & Group-based Independent\ncomponents using ICA (coming soon)\n - Activity & Connectivity maps - ALFF, fALFF, z-fALFF,\n z-ALFF, ReHo, ReHO_sz, 4D-heat maps\n - Normalised output & comfound file\nComing soon:\n - Group based independent components\n - White matter tracts\n - FA/ADC values`

                }
            ]
        }
    }
}
</script>

<style>
.input-output-section {
    width: 100%;
    height: 750px;
    background: rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    padding: 0 3%;
}

.io-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5%;
}

.io-item img {
    width: 25%;
}

.io-item .heading {
    font-size: 125%;
}

.io-item .para,
.io-item .heading {
    white-space: pre-wrap;
    /* height: 30%; */
    font-family: 'SF Pro Text';
    font-style: normal;
    font-weight: 400;
    line-height: 165%;
    text-align: center;
    color: rgba(0, 0, 0, 0.72);
}

.io-item .para {
    white-space: pre-wrap;
    height: 40%;
    width: 62%;
    font-size: 105%;
}

.io-item:nth-of-type(1) .para,
.io-item:nth-of-type(2) .para {
    font-size: 120%;
}

.io-item:nth-of-type(3) .para {
    text-align: left;
}

@media only screen and (min-width: 320px) and (max-width: 425px) {
    .input-output-section {
        grid-template-columns: 1fr;
        height: 900px;
    }

    .io-item .heading {
        font-size: 130%;
    }

    .io-item .para {
        height: auto;
    }
}
</style>