<template>
    <section class="header">
        <b-navbar variant="faded" type="light" sticky>
            <b-navbar-brand to="/"><img class="logo-with-text" src="../assets/loginLogo.png" alt="" />
            </b-navbar-brand>
            <!-- <b-button style="display:none,color:white" @click="showMenu" >Menus</b-button> -->
            <div class="nav-item-div" id="nav-item-div">
                <b-nav>
                    <b-nav-item target="_blank" href="https://www.brainsightai.com/">Home</b-nav-item>
                    <b-nav-item to="/"> Products</b-nav-item>
                    <b-nav-item target="_blank" href="https://www.brainsightai.com/copy-of-publications">Publications
                    </b-nav-item>
                    <b-nav-item target="_blank">Careers</b-nav-item>
                    <b-nav-item target="_blank" href="https://www.brainsightai.com/contact-3">Contact us</b-nav-item>
                    <!-- <b-nav-item to="/Login">Login</b-nav-item> -->
                </b-nav>
            </div>
        </b-navbar>
    </section>
</template>

<script>
export default {
  methods:{
    showMenu(){
      document.getElementById("nav-item-div").style.display="block"
    }
  }
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@600&display=swap");

.navbar {
    height: 92px;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    display: flex;
    gap: 15%;
    align-items: center;
}

.navbar-brand {
    margin-left: 4%;
}

.logo-with-text {
    max-width: 210px;
}

.nav-item-div{
    width: 100%;
}

.nav {
    display: flex;
    gap: 5%;
    justify-content: center;
    width: 100%;
    list-style: none;
}

.nav-item {
    color: white;
    border-bottom: none;
}

.nav-item::after {
    display: block;
    content: "";
    border-bottom: 3px solid #5dfaad;
    transform: scaleX(0);
    transition: transform 250ms ease-in-out;
}

.nav-item:hover::after {
    border-bottom: 3px solid #5dfaad;
    cursor: pointer;
    transform: scale(1);
}

.nav-item a {
    font-family: "Open Sans", sans-serif;
    font-style: normal;
    font-size: 110%;
    line-height: 28px;
    text-decoration: none;
    color: #ffffff;
}

/* 
@media (min-width: 768px) {
  .logo-with-text {
    width: 90%;
  }
  .nav {
    left: 34%;
    width: 72%;
  }
  .nav-item {
    margin-right: 5%;
  }
  .nav-item a {
    font-size: 95%;
  }
}

@media (min-width: 992px) {
  .logo-with-text {
    width: 92%;
  }
  .nav-item a {
    font-size: 110%;
  }
  .nav{
    left: 33%;
    width: 70%;
  }
}
@media (min-width: 1400px) {
  .logo-with-text {
    width: 100%;
  }
  .nav {
    left: 39%;
    width: 64%;
  }
  .nav-item {
    margin-right: 8%;
  }
} */

@media only screen and (min-width: 320px) and (max-width: 425px) {
    .header .btn{
      display: block;
    }
    .nav-item-div{
      display: none;
    }
    
}
@media only screen and (min-width: 425px) and (max-width: 768px) {
    .header .btn{
      display: block;
    }
    .nav-item-div{
      display: none;
    }
}
</style>