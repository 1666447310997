import router, { AxiosConfig, AxiosConfig2, PROVIDER, ENV, AxiosReportConfig } from "../router";
import { ifUnauthorized } from "./index";

const inactivityLogoutTime = 30 * 60 * 1000 //30 mins
const inactivityPollRate = 10 * 1000 //10 secs

/* eslint-disable no-unused-vars */
let activityCount = 0, lastActiveTimeout, pollInterval;
let userHasBeenActiveWithinTimeLimit = true

const eventsToListenFor = ['keydown', 'mousemove', 'scroll', 'uploading', 'downloading']

let InactivityLoggingStarted = false

function increaseActivityCount(event) {activityCount++}

// ***TYPE IN CONSOLE TO STOP INACTIVITY LOGGING***
// console.log(Array.from(document.querySelectorAll('*')).find(e => e.__vue__).__vue__.$store._actions['auth/stopAuthInactivityLogging'][0]())

function StartInactivityLogging() {
  if(!InactivityLoggingStarted) {
    console.log('INACTIVITY LOGGGING STARTED!')
    InactivityLoggingStarted = true
    eventsToListenFor.forEach(x => window.addEventListener(x, increaseActivityCount))
    
    lastActiveTimeout = setTimeout(() => {userHasBeenActiveWithinTimeLimit = false; ifUnauthorized({logoutStatus: true}); StopInactivityLogging()}, inactivityLogoutTime) 
    pollInterval = setInterval(
      () => {
        if(activityCount) { 
          clearTimeout(lastActiveTimeout);
          lastActiveTimeout = setTimeout(() => {userHasBeenActiveWithinTimeLimit = false; ifUnauthorized({logoutStatus: true}); StopInactivityLogging()}, inactivityLogoutTime) 
        } 
        activityCount = 0;
      }, inactivityPollRate)
  }
}

function StopInactivityLogging() {
  if(InactivityLoggingStarted) {
    console.log('INACTIVITY LOGGGING STOPPED!')
    InactivityLoggingStarted = false
    clearInterval(pollInterval)
    clearTimeout(lastActiveTimeout); 
    eventsToListenFor.forEach(x => window.removeEventListener(x, increaseActivityCount))
  }
}

const emptySeriesDescObj = JSON.stringify({fmri: [], dmri: [], t1w: [], t1c: [], flair:[], t2w:[]})
const TimeOutPeriod = (60 - 5) * 60 * 1000 //57mins in token refreshes
// function printExact(a, x) { console.log(a, JSON.parse(JSON.stringify(x))) }

const user = JSON.parse(localStorage.getItem("user"));
const initialState = user
  ? { status: { loggedIn: true }, user }
  : { status: { loggedIn: false }, user: null };

export const auth = {
  namespaced: true,
  state:
    initialState,
  actions: {
    async fetchSeriesKeywords({state}) {
      await AxiosConfig.get('/fetchSeriesDesc', {
        params: {env: ENV, provider: PROVIDER}
      })
      .then(SD => {if(typeof SD.data === 'object') {
        SD.data.optionSeriesKeywords? state.user.seriesDescriptions = SD.data.optionSeriesKeywords : null
        SD.data.selectedSeriesKeywords? state.user.selectedDescriptions = SD.data.selectedSeriesKeywords : null
      }})
    },
    async refreshLogin({state, dispatch}) {
      // printExact('refresh old: ', AxiosConfig2.defaults.headers.common["x-access-token"])
      console.log('LOGIN refreshed')
      clearTimeout(state.user.refreshTimeOut)
      if(userHasBeenActiveWithinTimeLimit) {
        let newToken = null;
        await AxiosConfig.get("/refreshToken").then(
          x => {
            newToken = x.data.newToken
            dispatch("setHeaderToken", newToken)
            state.user.refreshTimeOut = setTimeout(async () => {await dispatch("refreshLogin")}, TimeOutPeriod)
            // printExact('refresh new: ', AxiosConfig2.defaults.headers.common["x-access-token"])
          }
        )
      }
    },
    StartInactivityLogging() {StartInactivityLogging()},
    StopInactivityLogging() {StopInactivityLogging()},
    login({ commit, dispatch }, user) {
      return AxiosConfig
        .post("/login", {
          email: user.email,
          password: user.password
        })
        .then(
          response => {
            response.data = {...response.data.user, ...response.data.permissions, name: response.data.roles_name?.join(',')}
            if (response.data.token) {
              if (!Object.prototype.hasOwnProperty.call(response.data, 'admin')) { response.data['admin'] = false; }
              response.data.seriesDescriptions = JSON.parse(emptySeriesDescObj); 
              response.data.selectedDescriptions = JSON.parse(emptySeriesDescObj);
              response.data.when = Date.now();
              localStorage.setItem("user", JSON.stringify(response.data));
            }
            commit("loginSuccess", response.data);
            dispatch("setHeaderToken", response.data.token);
            // eslint-disable-next-line
            if(response.data['isHospitalUser']) {
              if(response.data.hospitalID === '61') {router.push("/hospital-user-upload");}
              if(response.data.hospitalID === '63') {router.push("/hospital-user-upload2");}
            }
            else if(response.data['admin']) {router.push("/admin-page");}
            else {router.push("/upload-page");}
            StartInactivityLogging()
            return Promise.resolve(response.data);
          },
        ).catch(
          error => {
            commit("loginFailure");
            return Promise.reject(error);
          }
        );
    },
    setHeaderToken({state}, token) {
      state.user.token = token;
      AxiosConfig.defaults.headers.common["x-access-token"] = token
      AxiosConfig2.defaults.headers.common["x-access-token"] = token
      AxiosReportConfig.defaults.headers.common["x-access-token"] = token
      const user = JSON.parse(localStorage.getItem("user"))
      if(user) {user.token = token; localStorage.setItem("user", JSON.stringify(user))}
      // console.log(JSON.parse(localStorage.getItem("user")).token)
    },
    stopAuthInactivityLogging() { StopInactivityLogging() },
    register({ commit }, user) {
      return AxiosConfig
        .post("/register", user)
        .then(
          response => {
            console.log(response)
            commit("registerSuccess", response.data);
            this.secondPage = false;
            this.thirdPage = true;
            return Promise.resolve(response.data);
          },
          error => {
            console.log("ERROR: ", error)
            console.log("ERROR RESPONSE: ", error.response)
            let errMsg
            if(Object.prototype.hasOwnProperty.call(error, 'response')) {
              errMsg = (error?.response?.data?.msg)
            } else {
              errMsg = error
            }
            commit("registerFailure");
            return Promise.reject(errMsg);
          }
        );
    },
    logout({ commit }) {
      localStorage.removeItem("user");
      delete AxiosConfig.defaults.headers.common["x-access-token"];
      delete AxiosConfig2.defaults.headers.common["x-access-token"];
      commit("logout");
      router.push("/login");
    },
  },
  mutations: {
    loginSuccess(state, user) {
      state.status.loggedIn = true;
      state.user = user;
    },
    loginFailure(state) {
      state.status.loggedIn = false;
      state.user = null;
    },
    logout(state) {
      state.status.loggedIn = false;
      state.user = null;
    },
    registerSuccess(state) {
      state.status.loggedIn = false;
    },
    registerFailure(state) {
      state.status.loggedIn = false;
    }
  },
  getters: {
    getUser: (state) => {return state.user},
    getAdminRole: (state) => {
      return state.user?.admin ?? false;
    },
    getSeriesDescSuggest: (state) => {
      return state.user.seriesDescriptions
    },
    getSeriesDescSelect: (state) => {
      return state.user.selectedDescriptions
    },
    getCanUserUseViewer: (state) => {
      return true //state.user.canUserUseViewer || state.user.canHospitalUseViewer
    }
  }
};
