import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/HomeExplore.vue'
import store from '../store'
import axios from 'axios'

Vue.use(VueRouter)

// function getAuth() { return 'Basic QnNhaUAyMDIzOkJzYWlAMjAyMw==' } // dev
function getAuth() { return 'Basic QnNhaUAyMDIyOkJzYWlAMjAyMg==' } // prod

const SCAN_NAME= {fmri: 'rs-fMRI', t1c: 'T1c', t1w: 'T1w', t2w: 'T2w', flair: 'FLAIR', dmri: 'DTI',}
const SCAN_NAME2= {fmri: 'rs-fMRI', t1c: 'T1c', t1w: 'T1w', t2w: 'T2w', flair: 'FLAIR', dmri: 'dMRI'}
const SCAN_NAME_REVERSE= {'rs-fMRI': 'fmri', 'T1c': 't1c', 'T1w': 't1w', t2w: 't2w', 'FLAIR': 'flair', 'dMRI': 'dmri'}
const SCAN_PARAM_TO_SERIES_DESC= {'functional': 'fmri', 'smri_t1w': 't1w', 'dmri': 'dmri', 'smri_t1ce': 't1c', 'smri_flair': 'flair', 'smri_t2': 't2w'}
const SCAN_PARAM_TO_DISP_NAME= {'functional': 'rs-fMRI', 'smri_t1w': 'T1w', 'dmri': 'dMRI', 'smri_t1ce': 'T1c', 'smri_flair': 'FLAIR', 'smri_t2': 'T2w'}
const UPDATE_RATE= 120*1000
const reUploadPop = 120*1000

const PROVIDER = 1
// const ENV = 1 // dev
const ENV = 2 // prod

// const baseUrl = 'http://localhost:3769'; //local
// const baseUrl = 'https://dev-wbt-backend.brainsightai.com'; //dev
// const baseUrl = 'https://uat-wbt-backend.brainsightai.com' //uat
const baseUrl = 'https://prod-wbt-backend.brainsightai.com' //prod-aws
// const baseUrl = 'https://wbt-backend.brainsightai.com'; //master

//Used for fetchAllPatients and downloadResults
// const baseUrl2 = 'http://localhost:3768'; //local
// const baseUrl2 = 'https://dev-explorefileprocess.brainsightai.com' //dev
// const baseUrl2 = 'https://uat-explorefileprocess.brainsightai.com'; //uat
const baseUrl2 = 'https://prod-explorefileprocess.brainsightai.com'; //prod-aws
// const baseUrl2 = 'https://prod-explorefileprocess.brainsightai.com' //master

// const baseUrl3 = 'https://uat-fileprocessing-service.brainsightai.com' //dev report
const baseUrl3 = 'https://prod-fileprocessing-service.brainsightai.com' //prod report

// const ReportURL = 'https://uat-voxelboxreporting.brainsightai.com/' // REPORTING UAT
const ReportURL = 'https://prod-voxelboxreporting.brainsightai.com/' // REPORTING PROD

// const ViewerURL = 'https://uat-bsaiviewer.brainsightai.com/' // VIEWER UAT
const ViewerURL = 'https://prod-bsaiviewer.brainsightai.com/' // VIEWER PROD

// const AxiosConfigOrthancServBaseURL = 'https://uat-orthanc.brainsightai.com/dicom-web' //uat orthanc
const AxiosConfigOrthancServBaseURL = "https://prod-orthanc.brainsightai.com/dicom-web" //dev orthanc

const AxiosConfig = axios.create({ baseURL: baseUrl });

const AxiosConfig2 = axios.create({ baseURL: baseUrl2 })

const AxiosReportConfig = axios.create({ baseURL: baseUrl3 })

const AxiosConfigOrthancServ = axios.create({ baseURL: AxiosConfigOrthancServBaseURL, headers: { 'Authorization': getAuth() } })



if (JSON.parse(localStorage.getItem("user"))) {
    AxiosConfig.defaults.headers.common["x-access-token"] = JSON.parse(localStorage.getItem("user")).token;
    AxiosConfig2.defaults.headers.common["x-access-token"] = JSON.parse(localStorage.getItem("user")).token;
    AxiosReportConfig.defaults.headers.common["x-access-token"] = JSON.parse(localStorage.getItem("user")).token;

}

const routes = [
    {
        path: '/',
        name: 'Home',
        redirect: '/login',
        component: Home,
        meta: {
            requiresAuth: false
        }
    },
    {
        path: '/login',
        name: 'Login',
        // component: Login
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/Login_Harsh-2.vue'),
        meta: {
            requiresAuth: false
        }
    },
    {
        path: '/research-papers',
        name: 'researchParams',
        // component: Login
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/ResearchPapers.vue'),
        meta: {
            requiresAuth: false
        }
    },
    {
        path: '/how-to-view-results',
        name: 'viewResults',
        // component: Login
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/viewResults.vue'),
        meta: {
            requiresAuth: false
        }
    },
    {
        path: '/verify',
        name: 'verifyEmail',
        // component: Login
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/VerifyEmail.vue'),
        meta: {
            requiresAuth: false
        }
    },
    {
        path: '/acquisition-params',
        name: 'accquisitionParams',
        // component: Login
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/AcqParams.vue'),
        meta: {
            requiresAuth: false
        }
    },
    {
        path: '/expected-output',
        name: 'expectedOutput',
        // component: Login
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/ExpectedOutput.vue'),
        meta: {
            requiresAuth: false
        }
    },

    //Path only accessed from outside for now
    {
        path: '/VoxelboxExploreLogin',
        name: 'voxelBoxLogin',
        // component: Login
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/VoxelBoxLogin.vue'),
        meta: {
            requiresAuth: false
        }
    },
    {
        path: '/register',
        name: 'Register',
        // component: Register
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/HospitalUserRegister.vue'),
        meta: {
            requiresAuth: false
        }
    },
    {
        path: '/reset',
        name: 'resetPassword',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/Forgot_Password.vue'),
        meta: {
            requiresAuth: false
        }
    },
    {
        path: '/AccessThankyou',
        name: 'AccessThankyou',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/ThankyouEarlyAccess.vue'),
        meta: {
            requiresAuth: false
        }
    },
    /*
    {
      path: '/welcome/:profileName',
      name: 'Welcome',
      // component: Register
      component: () => import(/* webpackChunkName: "about" */
    /* '../views/Welcome.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/fileUpload',
      name: 'fileUpload',
      // component: Register
      component: () => import(/* webpackChunkName: "about" */
    /* '../views/FileUpload.vue'),
      meta: {
        requiresAuth: true
      }
    },
    */
    {
        path: '/upload-page2',
        query: {subjectID: ''},
        name: 'fileUpload',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/fileUpload-Harsh.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/upload-page',
        query: {subjectID: ''},
        name: 'DashboardV2',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/DashboardV2.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/select-output',
        name: 'NewPatientUpload',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/UploadNewPatient.vue'),
        // meta: {
        //     requiresAuth: true
        // }
    },
    {
        path: '/hospital-user-upload',
        name: 'HospitalUserUpload',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/HospitalUserFileupload.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/hospital-user-upload2',
        name: 'HospitalUserUpload',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/HospitalUserFileuploadManipal.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/EDC',
        name: 'EDCForms',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/EDCForms.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/admin-page',
        name: 'Welcome Admin',
        component: () =>
            import ( /* webpackChunkName: "admin" */ '../views/AdminPage-Harsh.vue'),
        meta: {
            requiresAuth: true
        }
    },
]


const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

// router.beforeEach((to, from, next) => {
//   // check vuex store //
//   const isAuthenticated = store.state.auth.status.loggedIn;
//   if (to.name !== "Login" && !isAuthenticated) next("/login");
//   // if ((to.name == "register") && !isAuthenticated) next("/register");
//   else next();
// });

router.beforeEach((to, from, next) => {
    const isAuthenticated = store.state.auth.status.loggedIn;
    // console.log(isAuthenticated)
    if (to.matched.some(record => record.meta.requiresAuth)) {

        // console.log(to.matched.some(record => record.meta.requiresAuth))
        if (isAuthenticated == false) {
            next({
                path: '/login'
            })
        } else {
            next()
        }
    } else {
        next()
    }
})

export default router;
export { 
    AxiosConfig, 
    AxiosConfig2, 
    AxiosReportConfig, 
    AxiosConfigOrthancServ, 
    baseUrl, 
    baseUrl2, 
    ENV, 
    PROVIDER, 
    SCAN_NAME, 
    SCAN_NAME2, 
    SCAN_NAME_REVERSE, 
    UPDATE_RATE, 
    reUploadPop, 
    getAuth, 
    SCAN_PARAM_TO_SERIES_DESC, 
    SCAN_PARAM_TO_DISP_NAME, 
    ReportURL, 
    ViewerURL 
};